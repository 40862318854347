import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.css']
})
export class DirectoryComponent implements OnInit {

  articles: any = [];

  constructor(private postService: PostService) {}

  ngOnInit(): void {
    this.postService.getAllActors().subscribe((response: any) => {
      this.articles = response.metadata;
      this.articles.map((a: any) => {
        a.slug = this.slugify(a.title);
      });
    });
  }

  slugify(str: string) {
    const slug = this.postService.slugify(str);
    return slug;
  }
}
