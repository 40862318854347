<div class="wrapper">
    <h1>Explore Lyrics</h1>
    <section>
        <div class="songs" *ngIf="songs.length !== 0">
            <mat-card *ngFor="let song of songs" role="group">
                <mat-card-title class="song-card">
                    <div mat-card-avatar class="actor-header-image">
                        <fa-icon [icon]="faMusic"></fa-icon>
                    </div>
                    <a routerLink="{{ song.title | slugify }}/{{ song.artist | slugify }}">
                        <h2 class="song-title">{{ song.title }}</h2>
                        <h5 class="song-artist">{{ song.artist }}</h5>
                    </a>
                </mat-card-title>
            </mat-card>
        </div>
    </section>
</div>