import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '../../assets/endpoints';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  
  constructor(private http: HttpClient) { }

  getPost(slug: string) {
    return this.http.get(`${endpoints.prod.post}-${slug}.json`);
  }

  getRecentPosts(pageNumber = 1, pageSize = 5): Observable<any> {
    const url = `${endpoints.prod.recentPosts}?page=${pageNumber}&pageSize=${pageSize}`;
    return this.http.get(url)
  }

  getAllPosts() {
    return this.http.get(`${endpoints.prod.postsMetadata}`);
  }

  getAllActors() {
    return this.http.get(`${endpoints.prod.postsActors}`);
  }

  getAllSongs() {
    return this.http.get(`${endpoints.prod.postsSongs}`);
  }

  getPostsByTag(tag: string) {
    return this.http.get(`${endpoints.prod.postsByTag}?tag=${tag}`);
  }

  slugify(str: string) {
    // Remove quotes and convert to lowercase
    str = str.replace(/['"]/g, '').toLowerCase();

    // Replace non-word characters with spaces
    str = str.replace(/\W+/g, ' ');

    // Replace multiple consecutive spaces with a single hyphen
    str = str.replace(/\s+/g, '-');
    
    str = str.replace(/-$/, '');

    return str.trim();
  }
}
