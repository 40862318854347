import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PostComponent } from './components/post/post.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { HttpClientModule } from '@angular/common/http';
import { PostService } from './services/post.service';
import { BlogComponent } from './components/blog/blog.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { TagsComponent } from './components/tags/tags.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator'; 
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { BacktotopComponent } from './components/common/backtotop/backtotop.component';
import { BubblePaginationDirective } from './bubble-pagination.directive';
import { FormsModule } from '@angular/forms'; 

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { HelpComponent } from './components/help/help.component';
import { CookieComponent } from './components/cookie/cookie.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { CareersComponent } from './components/careers/careers.component';
import { GuidelinesComponent } from './components/guidelines/guidelines.component';
import { SupportComponent } from './components/support/support.component';
import { ExploreComponent } from './components/explore/explore.component';
import { PageComponent } from './components/page/page.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { TranslatePipe } from './translate.pipe';
import { ActorsComponent } from './components/actors/actors.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { TagPipe } from './tag.pipe';
import { SlugifyPipe } from './slugify.pipe';
import { LyricsComponent } from './components/lyrics/lyrics.component';
import { SongComponent } from './components/song/song.component';

@NgModule({
  declarations: [
    AppComponent,
    PostComponent,
    NotfoundComponent,
    BlogComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    TagsComponent,
    BacktotopComponent,
    BubblePaginationDirective,
    PrivacyComponent,
    TermsComponent,
    HelpComponent,
    CookieComponent,
    CopyrightComponent,
    QuizComponent,
    CareersComponent,
    GuidelinesComponent,
    SupportComponent,
    ExploreComponent,
    PageComponent,
    CategoriesComponent,
    TranslatePipe,
    ActorsComponent,
    DirectoryComponent,
    TagPipe,
    SlugifyPipe,
    LyricsComponent,
    SongComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    FontAwesomeModule
  ],
  providers: [
    PostService,
    HeaderComponent,
    HomeComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(
      fab,
      fas,
      far
    );
  }
 }
