<div class="wrapper">
    <section>
        
        <div class="quiz" *ngIf="!isQuizCompleted">
            
            <h1 class="quiz-headline">K-Pop Trivia!</h1>

            <h2>Question {{ currentQuestion + 1 }}</h2>
            
            <p>{{ progress }}% Completed</p>
            
            <div class="progress-bar">
                <div class="progress-bar-fill" [ngStyle]="{'width.%': progress}"></div>
            </div>
      
            <h2 class="quiz-question">{{ questionList[currentQuestion].questionText | translate: languageGlobalValue }}</h2>

            <ul class="quiz-questions">
              <li *ngFor="let option of questionList[currentQuestion].options; let i = index;">
                <button class="quiz-selected-answer" (click)="answer(option, i)" [disabled]="answeredOnce">{{ option.text }}</button>
              </li>
            </ul>
        </div>
          
        <div class="quiz-controls">
            <button class="quiz-edit" (click)="changeQuestion()" [disabled]="isQuizCompleted">Edit Answer <fa-icon [icon]="['fas', 'pen-to-square']"></fa-icon></button>
            <button class="quiz-next" (click)="nextQuestion()" [disabled]="isQuizCompleted">Next Question <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon></button>
        </div>
          
          <div class="quiz" *ngIf="isQuizCompleted">
            <h1  class="quiz-headline">Quiz Finished!</h1>

            <h2>Your Score: {{ correctAnswer }} / {{ questionList.length }}</h2>

            <p>You got {{ correctAnswer }} out of {{ questionList.length }} answers correct.</p>

            <p>Correct Answers: {{ correctAnswer }}</p>
            <p style="visibility: hidden;">Incorrect Answers: {{ incorrectAnswer }}</p>
          </div>

        <div class="quiz-controls" *ngIf="isQuizCompleted">
            <button class="quiz-reset" (click)="restartQuiz()">Restart Quiz <fa-icon [icon]="['fas', 'arrow-right']"></fa-icon></button>
        </div>  
    </section>
</div>

