<div class="wrapper">
    <section>
        <h1>Site Guidelines</h1>
        <p>Embrace these guidelines, and let them guide your creativity as you bring the Switcher identity to life through design. These guidelines are essential for ensuring that our brand is consistently communicated across all platforms and to every visitor. In this article, we'll delve into key components that help define our brand's look and feel.</p>
        <h2>Logo Usage</h2>
        <p>Our logo must always be presented according to specified guidelines to maintain brand integrity. This includes protection space, which refers to the area surrounding the logo that must remain free of other visual elements, ensuring it stands out clearly. The logo should only be used in approved color formats and should never be altered in a way that compromises its recognition.</p>
      
        <ul>
            <li>1. Don't change the colors of the logo.</li>
            <li>2. Don't place elements in the logo clear space.</li>
            <li>3. Don't condense, expand, or distort the logo disproportionately.</li>
            <li>4. Don't add a drop shadow, bevel and emboss, inner glow, or any other text effects to the logo.</li>
            <li>5. Don't adjust the placement of the logo icon.</li>
            <li>6. Don't place the logo on top of an image with poor contrast and readability.</li>
            <li>7. Don't resize any individual elements of the logo.</li>
            <li>8. Don't rotate the logo.</li>
            <li>10. Don't remove the background from the icon mark.</li>
        </ul>
    
        <h2>Typography</h2>
        <p>Our primary typeface reflects modernity and professionalism, aligning with our brand's ethos. Utilize heading, body, and caption styles consistently to foster a coherent flow of information. Accessibility should also guide our typography choices, ensuring text is legible for all users.</p>
        <h2>Color Palette</h2>
        <p>Color acts as a powerful emotional trigger and can significantly impact user perception. Designers should reference the color codes (HEX, RGB) provided in the guidelines to ensure consistency across digital and print media.</p>
        <h2>Iconography and Patterns</h2>
        <p>The icon set should be cohesive in style and color, ensuring that each icon resonates with our overall aesthetic. Striking a balance between visual elements and information is crucial to maintain a clean, functional design.</p>
        <h2>Key Positioning and Tone</h2>
        <p>Our brand voice—whether it's friendly, professional, or playful—ensuring that our audience feels engaged and connected.</p>
        <ul>
            <li>Identity: Innovative</li>
            <li>Language: Sincere, accessible</li>
            <li>Voice: Direct, confident, encouraging, upbeat</li>
            <li>Characteristics: Genuine, imaginative, positive, open-minded</li>
        </ul>
    </section>   
</div>