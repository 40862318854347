import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit {

  articles: any = [];

  constructor(private postService: PostService) { }

  ngOnInit(): void {
    this.postService.getAllPosts().subscribe((response: any) => {
      this.articles = response.metadata;
      this.articles.map((a: any) => {
        a.slug = this.slugify(a.title);
      });
      console.log(this.articles);
    });
  }

  slugify(str: string) {
    const slug = this.postService.slugify(str);
    return slug;
  }

}
