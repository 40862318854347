import { Component, ElementRef, OnInit, QueryList, ViewChildren} from '@angular/core';
import { HomeComponent } from '../../home/home.component';
import { SharedService } from './../../../services/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  showSearchBar = false;

  // calling the input field value via angular special tag
  @ViewChildren('filter') myInputs!: QueryList<ElementRef>;

  constructor(
    public HomeComponent: HomeComponent,
    private sharedService: SharedService,
    private router: Router,
  ) {

  }

  clickMe(text: any) {
    this.sharedService.sendClickEvent(text);
    this.myInputs.forEach(input => {
      input.nativeElement.value = '';
    });
  }

  onSubmit(text: any) {
    this.sharedService.sendClickEvent(text);
    this.myInputs.forEach(input => {
      input.nativeElement.value = '';
    });
    return false;
  }
}
