import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slugify'
})
export class SlugifyPipe implements PipeTransform {

  transform(str: string): string {
    if ((str === null) || (str === '')) {
      return "";
    } else {
      
      // Make String 
      str = str.toString();
      
      // Remove quotes and convert to lowercase
      str = str.replace(/['"]/g, '').toLowerCase();

      // Replace non-word characters with spaces
      str = str.replace(/\W+/g, ' ');

      // Replace multiple consecutive spaces with a single hyphen
      str = str.replace(/\s+/g, '-');
      
      str = str.replace(/-$/, '');

      return str.trim();
    }
  }

}
