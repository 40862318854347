<div class="wrapper">
    <h1>Explore Song Lyrics - {{ artist | tag }} </h1>    
    <section>
        <div class="songs" *ngIf="foundLyricsToSong.length !== 0">
            <mat-card *ngFor="let song of foundLyricsToSong" role="group">
                <mat-card-title class="song-card">
                    {{ song.title }} - {{ song.artist }} - {{ song.korean_artist_name }}
                </mat-card-title>
                <mat-card-content>
                    {{ song.english_lyrics }}
                    {{ song.korean_lyrics }}
                </mat-card-content>
            </mat-card>
        </div>
    </section>
</div>