import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tag'
})
export class TagPipe implements PipeTransform {
  
  constructor() {}

  transform(str: string): string {
    if ((str === null) || (str === '')) {
      return "";
    } else {
      str = str.toString();
    }

    // Replace hyphens with spaces
    str = str.replace(/-/g, ' ');

    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

}
