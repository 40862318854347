<div class="wrapper">
    <section>
        <h1>Careers</h1>
        <h2>Are you a passionate job seeker looking for an opportunity to grow, learn, and thrive in a dynamic environment?</h2>
        <p>While we currently have no openings, we want to encourage you to keep your eyes on us as we prepare for a bright future filled with possibilities.</p>
        <h2>What sets us apart?</h2>
        <p>We believe diversity in perspectives fuels creativity and problem-solving, which is why we're committed to creating an inclusive environment where everyone can excel. When we do have openings, we aim to hire those who align with our values and vision, so being part of our community now will give you a head start.</p>
        <h2>How do I apply?</h2>
        <p>By staying connected, you can gain insights into our company culture, the exciting projects we are working on, and the incredible people who make up our team. You never know when the right opportunity might arise, and you'll want to be ready to seize it!</p>
        <p><strong>Email: support@kpopceleb.com</strong></p>
    </section>   
</div>