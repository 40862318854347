<div class="wrapper">
    <h1>Blog</h1>
    <div class="tagged">
        <p *ngIf="postsWithTag.length > 1" class="posts-tagged">{{ postsWithTag.length }} posts tagged: #{{ tag }}</p>
        <p *ngIf="postsWithTag.length === 1" class="posts-tagged">{{ postsWithTag.length }} post tagged: #{{ tag }}</p>
        <button *ngIf="postsWithTag.length !== 0" (click)="clear()">clear</button>
    </div>
    <section>
        <mat-card *ngFor="let article of postsWithTag" role="group">
            <mat-card-title><a routerLink="/blog/{{ article.slug }}">{{ article.title }}</a></mat-card-title>
            <mat-card-subtitle class="date">Published on <time>{{ article.date | date }}</time> &bull; tagged with <a class="tag" *ngFor="let tag of article.tags" (click)="refresh(tag)" routerLink="/blog/topics/{{ tag }}">#{{ tag }}</a></mat-card-subtitle>
            <mat-card-content>{{ article.preview }}</mat-card-content>
        </mat-card>
    </section>
</div>
