<footer class="main">
    <div class="wrapper shorten">
        <div class="row summary-footer">
            <div  class="col-sm">
                <img class="footer-logo" src="./assets/images/logo.png" />
                <p class="summary">Get to know all the trending hot topics and gist about Korean stars</p>
            </div>
            <div  class="col-sm">
                <p class="social-headline">Join us on Social Media</p>
                <div class="social-media">
                    <a class="social-link" routerLink="{{ socials.facebook }}"><fa-icon [icon]="faFacebook"></fa-icon></a>
                    <a class="social-link" routerLink="{{ socials.instagram }}"><fa-icon [icon]="faInstagram"></fa-icon></a>
                    <a class="social-link" routerLink="{{ socials.pinterest }}"><fa-icon [icon]="faPinterest"></fa-icon></a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <h3>Information</h3>
                <ul class="information-nav">
                    <li><a routerLink="{{ nav.about }}">About Us</a></li>
                    <li><a routerLink="{{ nav.terms }}">Terms of Use</a></li>
                    <li><a routerLink="{{ nav.privacy }}">Privacy Policy</a></li>
                </ul>
            </div>
            <div class="col-md">
                <h3>more</h3>
                <ul class="more-nav">
                    <li><a routerLink="{{ nav.guidelines }}">Site Guidelines</a></li>
                    <li><a routerLink="{{ nav.help }}">Help Center</a></li>
                    <li><a routerLink="{{ nav.careers }}">Careers</a></li>
                </ul>
            </div> 
            <div class="col-md">
                <h3>Helpful Links</h3>
                <ul class="information-nav">
                    <li><a routerLink="{{ nav.support }}">Support</a></li>
                    <li><a routerLink="{{ nav.cookies }}">Cookie Policy</a></li>
                    <li><a routerLink="{{ nav.copyright }}">Copyright Policy</a></li>
                </ul>
            </div>
            <div class="col-md">
                <h3>Language</h3>

                <form [formGroup]="myForm">

                    <ul class="language-nav">
                        <li>
                            <label>
                                <input type="radio" formControlName="translation" value="en" (click)="triggerChange('en')"> English
                              </label>
                        </li>
                        <li>
                            <label>
                                <input type="radio" formControlName="translation" value="kr" (click)="triggerChange('kr')"> Korean
                              </label>
                        </li>
                        <li>
                            <label>language: {{ myForm.value.translation }}</label>
                        </li>
                    </ul>

                  </form>


            </div>
        </div>
    </div>
</footer>
<footer class="secondary">
    <div class="wrapper">
        <div class="copyright">
            <p>&copy; {{ currYear }} {{ author }}. All Rights Reserved.</p>
        </div>
        <div class="canvas">
            <div class="circles"></div>
        </div>
    </div>
</footer>