import { Component, OnInit, ViewChild } from '@angular/core';
import { site } from '../../../../assets/site.json';
import { socials } from '../../../../assets/site.json';
import { Router } from '@angular/router';

import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslationStatusService } from 'src/app/services/translation-status.service';

library.add(
  faCoffee as IconDefinition,
  faFacebook as IconDefinition,
  faInstagram as IconDefinition,
  faPinterest as IconDefinition
);

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  
  faCoffee = faCoffee;
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faPinterest = faPinterest;

  currYear: Number = 0;
  author: String = "";
  nav: any = {};
  socials: any = {};
  pathName: String = "";

  myForm = new FormGroup({
    translation: new FormControl('en')
  });

  constructor(private router: Router, private translationStatusSharedService: TranslationStatusService) {

  }

  ngOnInit(): void {
    this.pathName = window.location.pathname;
    this.currYear = this.getCurrentYear();
    this.author = site.author;
    this.socials = socials;
    this.nav = site.nav;
  }

  getCurrentYear() {
    const year = new Date().getFullYear();
    return year;
  }

  backToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }


  triggerChange(event: String) {
    console.log("what is the value of this input?", event)
    this.translationStatusSharedService.setButtonClickValue(event);
  }

}
