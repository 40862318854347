<div class="wrapper">
    <section>
        <h1>Contact</h1>
        <p>Feel free to send a message if you would like to see more news and kpop related stories. We accept your k-pop related submissions.</p>
        <form [formGroup]="contactForm" (ngSubmit)="submit()" class="contact-form">
            <mat-form-field appearance="outline">
                <mat-label>Name</mat-label>
                <input type="text" matInput formControlName="name" placeholder="Enter name">
                <mat-error *ngIf="cName.invalid">Name is required.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Email</mat-label>
                <input type="text" matInput formControlName="email" placeholder="Enter email">
                <mat-error *ngIf="!contactForm.submitted && cEmail.invalid && cEmail.errors?.required == true">Email is required.</mat-error>
                <mat-error *ngIf="!contactForm.submitted && cEmail.errors?.email == true">Invalid email format.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Message</mat-label>
                <textarea matInput name="message" id="message" formControlName="message" placeholder="Enter message"></textarea>
                <mat-error *ngIf="cMessage.invalid">Message is required.</mat-error>
            </mat-form-field>
            <div class="submit">
                <button title="Please fill in all form fields." *ngIf="contactForm.status === 'INVALID'" disabled type="submit">Submit</button>
                <button *ngIf="contactForm.status === 'VALID'" type="submit">Submit</button>
            </div>
        </form>
    </section>
</div>