import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationStatusService {
  private buttonClickValueSource = new BehaviorSubject<any>(null);

  // Accessing the Value 
  buttonClickValue$ = this.buttonClickValueSource.asObservable();

  // Pass value 
  setButtonClickValue(value: any) {
    this.buttonClickValueSource.next(value);
  }
}
