import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-song',
  templateUrl: './song.component.html',
  styleUrls: ['./song.component.css']
})
export class SongComponent implements OnInit {
  
  songs: any = [];
  filteredSongs: any = [];
  foundLyricsToSong: any = [];

  title: string = "";
  artist: string = "";

  constructor(private postService: PostService) { 
    console.log(window.location.pathname.split('/')[3]);
    console.log(window.location.pathname.split('/')[2]);
    this.title = window.location.pathname.split('/')[2];
    this.artist = window.location.pathname.split('/')[3]; 
  }

  ngOnInit(): void {
    this.postService.getAllSongs().subscribe((response: any) => {
      this.songs = response.metadata;
      this.songs.map((a: any) => {
        if (a.slug === this.slugify(this.artist)) {
          this.filteredSongs.push(a);
        }
      });
      this.filteredSongs.map((a: any) => {
        if (this.slugify(a.title) === this.title && this.slugify(a.artist) === this.artist) {
          this.foundLyricsToSong.push(a);
        } 
      });
    });

    console.log(this.foundLyricsToSong);
  }

  slugify(str: string) {
    const slug = this.postService.slugify(str);
    return slug;
  }

}
