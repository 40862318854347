import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-actors',
  templateUrl: './actors.component.html',
  styleUrls: ['./actors.component.css']
})
export class ActorsComponent implements OnInit {

  actorname: string = "";
  lowercaseActor: string = "";
  actors: any = [];
  filteredActors: any = [];
  
  constructor(private postService: PostService) { 
    this.actorname = this.sentenceCase(window.location.pathname.split('/')[2]);
    this.lowercaseActor = window.location.pathname.split('/')[2];
  }

  ngOnInit(): void {
    this.postService.getAllActors().subscribe((response: any) => {
      this.actors = response.metadata;
      this.actors.filter((a: any) => {
        if (a.slug === this.lowercaseActor) {
          this.filteredActors.push(a);
        }
      });
      this.actors = this.filteredActors;
    });
  }

  slugify(str: string) {
    const slug = this.postService.slugify(str);
    return slug;
  }
 
  sentenceCase(str: string): string {
    if ((str === null) || (str === '')) {
      return "";
    } else {
      str = str.toString();
    }

    // Replace hyphens with spaces
    str = str.replace(/-/g, ' ');

    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

}
