<div class="bg">
    <div class="header-wrapper">
        <header>  
            <h1 class="header-title">Explore The World Of Korean Stars And Stories</h1>
            <div class="profiles">
            
                <picture class="hero-img">
                    <source media='(min-width:0px)' srcset="./assets/images/hero-1/post.png">
                    <img mat-card-image src="" alt="Photo of a Shiba Inu">
                </picture>
       
                <picture class="hero-img">
                    <source media='(min-width:0px)' srcset="./assets/images/hero-2/post.png">
                    <img mat-card-image src="" alt="Photo of a Shiba Inu">
                </picture>
           
                <picture class="hero-img">
                    <source media='(min-width:0px)' srcset="./assets/images/hero-3/post.png">
                    <img mat-card-image src="" alt="Photo of a Shiba Inu">
                </picture>

                <picture class="hero-img">
                    <source media='(min-width:0px)' srcset="./assets/images/hero-4/post.png">
                    <img mat-card-image src="" alt="Photo of a Shiba Inu">
                </picture>
            </div> 
            <div class="canvas">
                 <div class="circles"></div>
             </div>
             <div class="hero-bg-canvas">
                <div class="hero-bg-circles"></div>
            </div>
         </header>
    </div>
    <div class="wrapper">
        <!-- <p class="hero-desc">A blog template built with Angular (Angular Material) and Node.js (Express). The blog posts are created within the <code>/pages</code> directory and are rendered on their respective pages using an API endpoint and a single "posts" component which acts as a template to display each blog post. Update the <code>/assets/blog.json</code> data with each blog posts metadata.</p> -->
        <section>
            <hr class="hr-text" data-content="Latest Trends">
            <div class="articles" *ngIf="recent !== undefined && posts.length > 0">
                <mat-card *ngFor="let article of posts" role="group">
                    <mat-card-title>
                        <picture>
                            <!-- https://material.angular.io/assets/img/examples/shiba2.jpg -->
                            <source media='(min-width:0px)' srcset="{{ article.image }}">
                            <img mat-card-image src="" alt="Photo of a Shiba Inu">
                        </picture>
                        <a routerLink="/explore/{{ article.dramatype }}/{{ article.slug }}">{{ article.title }}</a>
                    </mat-card-title>
                    <mat-card-subtitle>Published on <time>{{ article.date | date }}</time> 
                       <!-- &bull; tagged with <a class="tag" *ngFor="let tag of article.tags" routerLink="/blog/topics/{{ tag }}">#{{ tag }}</a> --> 
                    </mat-card-subtitle>
                    <!-- <mat-card-content>{{ article.preview }}</mat-card-content> -->
                </mat-card>
            </div>
        </section>
        <section>
            <mat-paginator #paginator
            appBubblePagination
                aria-label="Select page of blog posts"
                [appCustomLength]="recent.length"
                [length]="recent.length"
                [pageSize]="9"
                (page)="pageEvent = $event; onPaginateChange($event)">
            </mat-paginator>
        </section>
    </div>
</div>