import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ViewChild } from '@angular/core';
import { PostService } from 'src/app/services/post.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SharedService } from 'src/app/services/shared.service';

interface Post {
  title: string;
  image: string;
  date: Date;
  dramatype: string;
  slug: string;
  tags: string[];
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

  public posts: any = [];
  public filteredArticles: any = [];
  
  recent: Post[] = [];
  tags: String[] = [];
  currentPage = 1;
  pageSize = 9;
  totalPosts = 0;

  pageEvent: PageEvent = { pageIndex: 0, pageSize: 5, length: 0 };

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  clickEventsubscription: any;
  
  constructor(private postService: PostService,  
    private elementRef: ElementRef,
    private ren: Renderer2,
    private sharedService: SharedService) {
      // Must use shared services to subscribe to events from another component 
      this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(( value )=>{
        // very important this triggers the event from the nav (header component) and passes the text filter 
        this.filterResults(value);
        })
    }

  ngAfterViewInit() {
    this.fetchRecentPosts();
    this.styleDefaultPagination();
  }

  ngOnInit(): void {
    this.postService.getRecentPosts().subscribe((response: any) => {
      response.metadata.forEach((r: any) => {
        console.log(r);
        r.dramatype = r.dramatype;
        r.slug = this.postService.slugify(r.title);
        r.date = new Date(r.date);
        this.tags = r.tags;
      });
      this.recent = response.metadata;
    })
  }

  filterResults(text: string): void {
    if (text) {
      this.postService.getRecentPosts().subscribe((response: any) => {
        response.metadata.forEach((r: any) => {
          r.dramatype = r.dramatype;
          r.slug = this.postService.slugify(r.title);
          r.date = new Date(r.date);
          this.tags = r.tags;
        });
        this.recent = response.metadata;
        // Get input from search
        // console.warn(text)
        this.filteredArticles = this.recent.filter((actor: { title: string; }) => actor?.title.toLowerCase().includes(text.toLowerCase()));
        // Get filtered Articles 
        this.posts = this.filteredArticles;
        // console.log(this.posts)
      })
    } else {
      this.postService.getRecentPosts().subscribe((response: any) => {
        response.metadata.forEach((r: any) => {
          r.dramatype = r.dramatype;
          r.slug = this.postService.slugify(r.title);
          r.date = new Date(r.date);
          this.tags = r.tags;
        });
        this.recent = response.metadata;
        this.posts = this.recent;
        // console.log(this.posts)
      })
    }
  }

  fetchRecentPosts(pageNumber = 1, pageSize = this.pageSize): void {
    this.postService.getRecentPosts(pageNumber, pageSize).subscribe((response: any) => {
      this.recent = response.metadata.map((post: any) => ({
        title: post.title,
        image: post.image,
        date: new Date(post.date),
        dramatype: post.dramatype,
        slug: this.postService.slugify(post.title),
        tags: post.tags,
        // Add other post properties as needed
      }));

      // Check if pagination info exists (assuming it's not guaranteed)
      if (response.pagination && response.pagination.total) {
        this.totalPosts = response.metadata.length;
      } else {
        // Handle the case where total count is missing
        console.warn('Pagination count is an issue in response.');
        // You can choose to display an error message or set a default value for totalPosts
        this.totalPosts = 10;
      }

      // If initial data fetch, assign the paginator to the data source
      if (pageNumber === 1) {
        this.paginator.pageSize = this.pageSize;
        this.paginator.length = this.totalPosts;
      }

       // Calculate start and end indices for pagination
        const startIndex = (pageNumber - 1) * pageSize;
        const endIndex = startIndex + pageSize;

        // Slice the data to display the current page
        this.posts = this.recent.slice(startIndex, endIndex);

        console.log(this.posts)

    });
  }

  onPaginateChange(event: PageEvent) {
    this.currentPage = event.pageIndex + 1; // Adjust for 1-based indexing
    this.pageSize = event.pageSize;
    this.fetchRecentPosts(this.currentPage, this.pageSize);
  }

  private styleDefaultPagination() {
    const nativeElement = this.elementRef.nativeElement;

    const itemsPerPage = nativeElement.querySelector(
      '.mat-paginator-page-size'
    );
    const howManyDisplayedEl = nativeElement.querySelector(
      '.mat-paginator-range-label'
    );

    const arrows = nativeElement.querySelector(
      '.mat-paginator-range-actions'
    );

    const nextbutton = nativeElement.querySelector(
      '.mat-paginator-navigation-next'
    )

    const prevbutton = nativeElement.querySelector(
      '.mat-paginator-navigation-previous'
    )

    // remove 'items per page'
    this.ren.setStyle(itemsPerPage, 'display', 'none');

    // style text of how many elements are currently displayed
    this.ren.setStyle(howManyDisplayedEl, 'color', '#919191');
    this.ren.setStyle(howManyDisplayedEl, 'font-size', '14px');

    this.ren.setStyle(howManyDisplayedEl, 'display', 'none');

    this.ren.setStyle(arrows, 'align-items', 'center');
    this.ren.setStyle(arrows, 'justify-content', 'center');
    this.ren.setStyle(arrows, 'width', '100%');
    this.ren.setStyle(arrows, 'flex-direction', 'row');

    this.ren.removeAttribute(nextbutton, 'class', 'mat-icon-button');
    this.ren.setAttribute(nextbutton, 'class', 'mat-stroked-button');

    this.ren.removeAttribute(prevbutton, 'class', 'mat-icon-button');
    this.ren.setAttribute(prevbutton, 'class', 'mat-stroked-button');

  
    this.ren.setStyle(nextbutton, 'border-radius', '50%');
    this.ren.setStyle(prevbutton, 'border-radius', '50%');

    this.ren.setStyle(nextbutton, 'width', '36px');
    this.ren.setStyle(prevbutton, 'width', '36px');

    this.ren.setStyle(nextbutton, 'max-width', '36px');
    this.ren.setStyle(prevbutton, 'max-width', '36px');

    this.ren.setStyle(nextbutton, 'min-width', '36px');
    this.ren.setStyle(prevbutton, 'min-width', '36px');

    this.ren.setStyle(nextbutton, 'line-height', '36px');
    this.ren.setStyle(prevbutton, 'line-height', '36px');

    this.ren.setStyle(nextbutton, 'font-size', '12px');
    this.ren.setStyle(prevbutton, 'font-size', '12px');

    this.ren.setStyle(nextbutton, 'margin', '0px');
    this.ren.setStyle(prevbutton, 'margin', '0px');

    this.ren.setStyle(nextbutton, 'padding', '0px');
    this.ren.setStyle(prevbutton, 'padding', '0px');

  }
}
