import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/services/post.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  dramatype: string = "";
  lowercaseDramaType: string = "";
  articles: any = [];
  filteredArticles: any = [];

  constructor(private postService: PostService) { 
    this.dramatype = this.sentenceCase(window.location.pathname.split('/')[2]);
    this.lowercaseDramaType = window.location.pathname.split('/')[2];
  }

  ngOnInit(): void {
    this.postService.getAllPosts().subscribe((response: any) => {
      this.articles = response.metadata;
      this.articles.map((a: any) => {
        // console.log(a.slug)
        a.slug = this.slugify(a.title);
      });
      this.articles.filter((a: any) => {
        if (a.dramatype === this.lowercaseDramaType) {
          this.filteredArticles.push(a);
        }
      });
      this.articles = this.filteredArticles;
    });
  }

  slugify(str: string) {
    const slug = this.postService.slugify(str);
    return slug;
  }
 
  sentenceCase(str: string): string {
    if ((str === null) || (str === ''))
        return "";
    else
        str = str.toString();

    return str.replace(/\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() +
                txt.substr(1).toLowerCase();
        });
}
}
