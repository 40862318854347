<div class="wrapper">
    <h1>Actor {{ actorname }}</h1>
    <section>
        <div class="actors" *ngIf="actors.length !== 0">
            <mat-card *ngFor="let actor of actors" role="group">
                <mat-card-header>
                    <div mat-card-avatar class="actor-header-image" style="background-image: url('{{ actor.image }}');"></div>
                    <mat-card-title><a routerLink="/tv-film/">{{ actor.title }}</a></mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    {{ actor.preview }}
                  </mat-card-content>
                  <mat-card-subtitle class="date">Published on <time>{{ actor.date | date }}</time> &bull; Starred in <a class="tag" *ngFor="let starred of actor.tags" routerLink="/blog/topics/{{ starred }}">#{{ starred | tag }}</a></mat-card-subtitle>
                  <mat-card-actions>
                    <button mat-button class="actor-share">SHARE</button>
                  </mat-card-actions>
            </mat-card>
        </div>
    </section>
</div>

