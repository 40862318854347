import { Component, OnInit } from '@angular/core';
import { PostService } from 'src/app/services/post.service';

import { faMusic } from '@fortawesome/free-solid-svg-icons';
import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';

library.add(
  faMusic as IconDefinition
);

@Component({
  selector: 'app-lyrics',
  templateUrl: './lyrics.component.html',
  styleUrls: ['./lyrics.component.css']
})
export class LyricsComponent implements OnInit {
  
  faMusic = faMusic;

  songs: any = [];

  constructor(private postService: PostService) { }

  ngOnInit(): void {
    this.postService.getAllSongs().subscribe((response: any) => {
      this.songs = response.metadata;
      this.songs.map((a: any) => {
        console.log(a);
        a.slug = this.slugify(a.title);
      });
    });
  }

  slugify(str: string) {
    const slug = this.postService.slugify(str);
    return slug;
  }

}
