import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { PostComponent } from './components/post/post.component';
import { HomeComponent } from './components/home/home.component';
import { BlogComponent } from './components/blog/blog.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { TagsComponent } from './components/tags/tags.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { HelpComponent } from './components/help/help.component';
import { CookieComponent } from './components/cookie/cookie.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { QuizComponent } from './components/quiz/quiz.component';
import { SupportComponent } from './components/support/support.component';
import { GuidelinesComponent } from './components/guidelines/guidelines.component';
import { CareersComponent } from './components/careers/careers.component';
import { ExploreComponent } from './components/explore/explore.component';
import { PageComponent } from './components/page/page.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { ActorsComponent } from './components/actors/actors.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { SongComponent } from './components/song/song.component';
import { LyricsComponent } from './components/lyrics/lyrics.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog/:post", component: PostComponent },
  { path: "blog/topics/:tag", component: TagsComponent },
  { path: "explore", component: ExploreComponent},
  { path: "explore/:dramatype", component: CategoriesComponent},
  { path: "lyrics", component: LyricsComponent},
  { path: "lyrics/:artist/:songtitle", component: SongComponent},
  { path: "explore/:dramatype/:post", component: PageComponent},
  { path: "tv-film", component: DirectoryComponent },
  { path: "tv-film/:actorname", component: ActorsComponent},
  { path: "about", component: AboutComponent },
  { path: "contact", component: ContactComponent },
  { path: "terms", component: TermsComponent},
  { path: "support", component: SupportComponent},
  { path: "site-guidelines", component: GuidelinesComponent},
  { path: "careers", component: CareersComponent},
  { path: "privacy", component: PrivacyComponent},
  { path: "cookie-policy", component: CookieComponent},
  { path: "copyright-policy", component: CopyrightComponent},
  { path: "help", component: HelpComponent},
  { path: "quiz", component: QuizComponent},
  { path: "**", component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
