<div class="grad-bar"></div>
<nav id="#nav" class="menu">
    <div class="wrapper">
        <div class="logo"> 
            <a routerLink="/">
                <span><img class="header-logo" src="./assets/images/logo.png" /></span>
            </a>
        </div>
        <ul class="nav-links">
            <li class="menu-item">
                <a routerLink="/explore/">EXPLORE <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
                </a>
                <ul class="sub-menu">
                    <li class="menu-item"><a routerLink="/explore/action/">Action Dramas</a></li>
                    <li class="menu-item"><a routerLink="/explore/romantic/">Romantic Dramas</a></li>
                    <li class="menu-item"><a routerLink="/explore/comedy/">Comedy Dramas</a></li>
                    <li class="menu-item"><a routerLink="/explore/medical/">Medical Dramas</a></li>
                    <li class="menu-item"><a routerLink="/explore/psychological/">Psychological Dramas</a></li>
                    <li class="menu-item"><a routerLink="/explore/fantasy/">Fantasy Dramas</a></li>
                </ul>
            </li>
            <li class="menu-item"><a routerLink="/lyrics/">LYRICS</a></li>
            <li class="menu-item"><a routerLink="/quiz/">QUIZ</a></li>
            <li class="menu-item">
                <a routerLink="/tv-film/">TV/FILM</a>
                <ul class="sub-menu">
                    <li class="menu-item"><a routerLink="/tv-film/lee-min-ho/">Lee Min-ho</a></li>
                    <li class="menu-item"><a routerLink="/tv-film/kim-soo-hyun/">Kim Soo-hyun</a></li>
                    <li class="menu-item"><a routerLink="/tv-film/park-shin-hye/">Park Shin-hye</a></li>
                </ul>
            </li>
            <li class="menu-item"><a routerLink="/contact">CONTACT</a></li>
        </ul>
        <div class="profile"> 
            <div class="profile-icons">
                <button class="search-icon-trigger" (click)="showSearchBar = !showSearchBar">
                    <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon> 
                </button>
                <section *ngIf="showSearchBar">
                    <form (submit)="onSubmit(filter.value)">
                        <input class="search-input" type="text" placeholder="Search by Actor" #filter>
                        <button class="search-button" type="button" (click)="clickMe(filter.value)">Search</button>
                    </form>
                </section>
                <!-- <a routerLink="/user/">
                    <fa-icon [icon]="['fas', 'user']"></fa-icon>
                </a> -->
            </div>
        </div>
    </div>
</nav>