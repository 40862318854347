<div class="wrapper">
    <h1>Explore</h1>
    <section>
        <div class="article" *ngIf="articles.length !== 0">
            <mat-card *ngFor="let article of articles" role="group">
                <mat-card-title>
                    <div mat-card-avatar class="actor-header-image">
                        <img class="blog-img" *ngIf="article.image !== undefined" src="{{ article.image }}" alt="Some alt text">
                    </div>
                    <a routerLink="/explore/{{ article.dramatype }}/{{ article.slug }}">{{ article.title }}</a>
                </mat-card-title>
                <mat-card-content>{{ article.preview }}</mat-card-content>
            </mat-card>
        </div>
    </section>
</div>
