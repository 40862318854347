import { Pipe, PipeTransform } from '@angular/core';
import data from '../assets/quiz-questions.json';

interface Quiz {
  metadata: Questions[]
}

interface Questions {
  question_en: string;
  question_kr: string;
}

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  languageGlobalValue: string = "";
  change: Boolean = false;

  constructor() {}

  transform(element: string, globalLanguageChoice: string): string {
    let text = element;

    console.log("we have selected: ", globalLanguageChoice)
    
    // persisting the global language choice throughout site is important.

    let quiz: Quiz = data;

    console.log("what are the quiz questions?", quiz);

    // chosen trigger
    if (globalLanguageChoice === 'kr') {

      // if passed in element value matches english convert to korean 
      // search for english element from json
      for (let i = 0; i < quiz.metadata.length; i++) {
        if (element === quiz.metadata[i].question_en) {
          return quiz.metadata[i].question_kr;
        }
      }

    } else {
      // else revert to original text
        return text
    }

    // Default English users 
    return text;
  }

}
