import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslationStatusService } from 'src/app/services/translation-status.service';
import { Router } from '@angular/router';

interface Question {
  questionText: string;
  options: { text: string; correct: boolean }[];
  isAnswered: boolean;
}

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {
  questionList: Question[] =
    [
      {
        "questionText": "Which K-pop idol is also known for her professional figure skating skills?",
        "options": [
          { "text": "Krystal Jung", "correct": false },
          { "text": "Luna fx", "correct": true },
          { "text": "Sulli", "correct": false },
          { "text": "Hyoyeon", "correct": false }
        ],
        isAnswered: false
      },
      {
        "questionText": "What year did BoA make her debut?",
        "options": [
          { "text": "2000", "correct": true },
          { "text": "2001", "correct": false },
          { "text": "2002", "correct": false },
          { "text": "2003", "correct": false }
        ],
        isAnswered: false
      },
      {
        "questionText": "What are Blackpink fans called?",
        "options": [
          { "text": "Pinkies", "correct": false },
          { "text": "Blinks", "correct": true },
          { "text": "Blackpinkers", "correct": false },
          { "text": "Blinks Army", "correct": false }
        ],
        isAnswered: false
      }
    ];

  constructor(@Inject(DOCUMENT) private document: Document, private translationStatusSharedService: TranslationStatusService, private router: Router) { }

  currentQuestion = 0;
  points = 0;
  correctAnswer = 0;
  incorrectAnswer = 0;
  isQuizCompleted = false;
  progress = 0;
  answeredOnce = false;
  text = "";
  languageGlobalValue: string = "";
  change: Boolean = false;

  ngOnInit(): void {
     this.translationStatusSharedService.buttonClickValue$.subscribe((value: any) => {
        this.languageGlobalValue = value; // en, or kr
        console.log("We found your current language setting: ", this.languageGlobalValue);
      });
  }

  answer(option: { text: string; correct: boolean }, questionIndex: number) {
    this.progress = Math.round((this.currentQuestion + 1) * 100 / this.questionList.length);
    if (this.progress !== 100) {
      if (this.questionList[(this.currentQuestion)].isAnswered == false) {
        if (option.correct) {
          this.points++;
          this.correctAnswer++;
        } else {
          this.incorrectAnswer++;
        }
      }
    } else {
      if (this.questionList[(this.currentQuestion)].isAnswered == false) {
        if (option.correct) {
          this.points++;
          this.correctAnswer++;
        } else {
          this.incorrectAnswer++;
        }
      }
      this.progress = 100;
    }
    this.answeredOnce = true;
  }

  nextQuestion() {
      if ((this.currentQuestion + 1) === this.questionList.length) {
        this.isQuizCompleted = true;
      } else if ((this.currentQuestion + 1) < this.questionList.length) {
        this.currentQuestion++;
      }
      this.answeredOnce = false;
  }

  changeQuestion() {
    this.answeredOnce = false;
  }

  restartQuiz() {
    if (this.router === undefined) {
      this.document.location.href = '/quiz';
    } else {
      this.router.navigate(['/quiz']); 
    }
  }
}
