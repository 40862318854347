<div class="wrapper">
    <section>
        <h1>About Kpop Celeb</h1>
        <p>Is an independent news fan-based korean website started in 2018.</p>
        <h2>Our Mission</h2>
        <p>Explore the world of Korean stars and stories</p>
        <h2>Our Story</h2>
        <p>At the heart of every successful K-drama lies a story that resonates with viewers.</p>
        <p>Whether it's a romantic comedy, a gripping thriller, or a heartwarming family saga, K-dramas are known for their ability to evoke a wide range of emotions. </p>
        <p>The narrative arcs are often carefully crafted, ensuring that each episode leaves viewers wanting more.</p>
        <h2>Why Us?</h2>
        <p>Cliffhangers and unexpected plot twists keep audiences on the edge of their seats, fostering an emotional investment in the characters and their journeys.</p>
        <p>Fans find themselves immersed in tales that explore love, friendship, sacrifice, and personal growth.</p>
        <p>We enjoy giving back to the fandom!</p>
    </section>
</div>

